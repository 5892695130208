<template>
  <div>
    <v-card>
      <v-card-title>Publisher Onboarding - Affiliate Profile</v-card-title>
      <v-card-text>
        <v-row class="mb-1">
          <v-col cols="12" sm="6">
            <v-text-field
              label="Username*"
              required
              outlined
              dense
              v-model="user.username"
              persistent-hint
              hint="Please enter your preferred username"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Email*"
              required
              outlined
              dense
              v-model="user.email"
              hide-details="auto"
              persistent-hint
              hint="Please enter your E-mail address"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-select
              label="Your Are*"
              required
              outlined
              dense
              :items="specializations"
              v-model="user.specialization"
              persistent-hint
              hint="Choose who you are"
              hide-details="auto"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              label="Channel*"
              :items="channels"
              multiple
              v-model="user.channel"
              required
              outlined
              dense
              hide-details="auto"
            ></v-select>
          </v-col>
        </v-row>

        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" :disabled="!canSave" @click="saveUserData">Save</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    specializations: ['Influencer', 'Media Buyer', 'Marketing Agency', 'Other'],
    channels: ['Google', 'Facebook', 'Instagram', 'Tiktok', 'Email', 'Telegram', 'Discord', 'Youtube', 'Other'],
    user: {
      username: null,
      specialization: null,
      email: null,
      channel: [],
    },
  }),
  computed: {
    canSave() {
      return this.user.username && this.user.specialization && this.user.email && this.user.channel.length
    },
  },
  methods: {
    saveUserData() {
      if (this.loading) return

      this.loading = true

      this.$http
        .put('/user/data', this.user)
        .then(({ data }) => {
          if (data.success) {
            this.$store.dispatch(
              'notification/GENERATE_NOTIFICATION',
              {
                type: 'success',
                message: `Data successfully saved!`,
              },
              { root: true },
            )

            this.$store.commit('web3auth/UPDATE_USER', this.user)
            this.$router.push({ name: 'publisher' })
          }
        })
        .catch(err => {
          if (err.response.data) {
            this.$store.dispatch(
              'notification/GENERATE_NOTIFICATION',
              {
                type: 'error',
                message: err.response.data.message || 'Unknown error!',
              },
              { root: true },
            )
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
